import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import moment from "moment";

import { ModelArrayCast, ModelCast } from "shared/casts";
import { TaskTrackerTaskPriorityEnum, TaskTrackerTaskStatusEnum } from "shared/enums";

import { Model } from '../Model';

import { IUserModel, UserModel } from "../UserModel";
import { BoardModel, IBoardModel } from "./BoardModel";
import { ColumnModel, IColumnModel } from "./ColumnModel";
import { ISpaceModel, SpaceModel } from "./SpaceModel";
import { ITaskViewModel, TaskViewModel } from "./TaskViewModel";

export interface ITaskModel {
    id?: number;
    ownerUserId?: number;
    responsibleUserId?: number;
    columnId?: number;
    isSelected?: number,
    boardId?: number;
    spaceId?: number;
    sort?: number;
    name?: string;
    description?: string;
    createdAt?: string;
    expiredAt?: string;
    doneAt?: string | null;
    priorityId?: string;
    statusId?: string;
    usersIds?: number[];
    column?: IColumnModel,
    board?: IBoardModel,
    space?: ISpaceModel,
    users?: IUserModel[];
    taskViews?: ITaskViewModel[];
    files?: string[];
    responsibleUser?: IUserModel;
    ownerUser?: IUserModel;
}

export class TaskModel extends Model implements ITaskModel {
    casts = {
        users: new ModelArrayCast(UserModel),
        taskViews: new ModelArrayCast(TaskViewModel),
        board: new ModelCast(BoardModel),
        space: new ModelCast(SpaceModel),
        column: new ModelCast(ColumnModel),
        responsibleUser: new ModelCast(UserModel),
        ownerUser: new ModelCast(UserModel),
    };

    id = 0;
    isSelected = 0;
    ownerUserId = 0;
    responsibleUserId = 0;
    columnId = 0;
    boardId = 0;
    spaceId = 0;
    sort = 0;
    name = '';
    description = '';
    expiredAt = moment().add(1, 'hour').set('minutes', 0).format();
    createdAt = moment().format();
    doneAt: string | null = null;
    priorityId = TaskTrackerTaskPriorityEnum.Normal.id;
    statusId = '';
    usersIds: number[] = [];
    users: UserModel[] = [];
    taskViews: TaskViewModel[] = [];
    column: ColumnModel = new ColumnModel();
    board: BoardModel = new BoardModel();
    space: SpaceModel = new SpaceModel();
    responsibleUser: UserModel = new UserModel();
    ownerUser: UserModel = new UserModel();
    files = [];

    constructor(payload: ITaskModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            ownerUserId: observable,
            responsibleUserId: observable,
            isSelected: observable,
            columnId: observable,
            boardId: observable,
            spaceId: observable,
            sort: observable,
            name: observable,
            description: observable,
            expiredAt: observable,
            createdAt: observable,
            doneAt: observable,
            priorityId: observable,
            priority: computed,
            statusId: observable,
            users: observable,
            usersIds: observable,
            column: observable,
            board: observable,
            space: observable,
            responsibleUser: observable,
            ownerUser: observable,
            files: observable,
            taskViews: observable,

            createdAtMoment: computed,
            expiredAtMoment: computed,
            doneAtMoment: computed,
            expiredAtTime: computed,
            status: computed,
            statusText: computed,
            search: computed,
            update: action
        });

        this.update(payload);
    }

    get createdAtMoment() {
        if (!this.createdAt) {
            return moment();
        }

        return moment(this.createdAt);
    }

    get doneAtMoment() {
        if (!this.doneAt) {
            return moment();
        }

        return moment(this.doneAt);
    }

    get expiredAtMoment() {
        if (!this.expiredAt) {
            return moment();
        }

        return moment(this.expiredAt);
    }

    get expiredAtTime() {
        return this.expiredAtMoment.clone().format('HH:mm');
    }

    set expiredAtTime(value) {
        runInAction(() => {
            const [hours, minutes] = value.split(':');
            this.expiredAt = this.expiredAtMoment.clone().set('hours', +hours).set('minutes', +minutes).format();
        })
    }

    get search() {
        let searchable = [this.name];
        if (this.ownerUser) {
            searchable.push(this.ownerUser.name);
        }
        if (this.responsibleUser) {
            searchable.push(this.responsibleUser.name);
        }
        searchable = [...searchable, ...this.users.map(user => user.name)];
        return searchable.join(' ');
    }

    get priority() {
        return TaskTrackerTaskPriorityEnum.from(this.priorityId);
    }

    get status() {
        const status = TaskTrackerTaskStatusEnum.from(this.statusId)
        if (status.in([TaskTrackerTaskStatusEnum.Done, TaskTrackerTaskStatusEnum.Delayed, TaskTrackerTaskStatusEnum.Archive])) {
            return status;
        }

        const duration = moment.duration(this.expiredAtMoment.diff(moment()));
        const hoursToExpired = duration.asHours();

        if (hoursToExpired <= 0) {
            return TaskTrackerTaskStatusEnum.from(TaskTrackerTaskStatusEnum.Expired.id);
        }

        if (hoursToExpired < 24) {
            return TaskTrackerTaskStatusEnum.from(TaskTrackerTaskStatusEnum.AlmostExpired.id);
        }

        return TaskTrackerTaskStatusEnum.from(TaskTrackerTaskStatusEnum.InWork.id);
    }

    get statusText() {
        if (this.status.is(TaskTrackerTaskStatusEnum.Done)) {
            return 'Завершен'
        }

        return this.expiredAtMoment.format('D MMMM, HH:mm');
    }

    canSave = (user: UserModel) => {
        return user.isAdmin || this.ownerUserId === user.id || this.space.spaceOwners.some(spaceOwner => +spaceOwner.userId === +user.id);
    }
}
