import classnames from "classnames";
import { observer } from 'mobx-react';
import { PPDWidgetItemBonus } from "pages/profile-dashboard/components/PPDWidget/components/PPDWidgetItemBonus";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { ICONS } from "shared/constants";
import { useOnClickOutside, useStore } from "shared/hooks";
import { UserDashboardWidgetModel } from "shared/models";
import { UserDashboardWidgetDictionaryItemModel, UserDashboardWidgetItemBonusModel } from "shared/models";
import { UiIcon } from "shared/uikit";
import { currency, number } from "shared/utilities";

import { PPDWidgetItemValueProgress } from "../PPDWidgetItemValueProgress";
import { PPDWidgetGroups } from "../PPDWidgetGroups";

import './styles.scss';

import compareDown from '../../assets/compare-down.svg';
import compareUp from '../../assets/compare-up.svg';

type PropsType = {
    widget: UserDashboardWidgetModel
}

export const PPDWidgetBaseAndCompare = observer(({ widget }: PropsType) => {
    const store = useStore(() => ({
        isLoading: true,
        groupId: null as number | string | null,
        storeId: null as number | string | null,
        stores: [] as UserDashboardWidgetDictionaryItemModel[],
        storesIsOpened: false
    }));

    const validatedGroups = useCallback((storeId: number | string | null) => {
        const items = widget.items.filter(item => item.storeId === storeId);
        return widget.groups.filter(group => items.some(item => item.groupId === group.id));
    }, [widget]);

    useEffect(() => {
        //validate that all store has any items
        const stores = widget.stores.filter(store => widget.items.some(item => item.storeId === store.id));
        let defaultStore = stores.find(store => store.id === null);
        if (!defaultStore && stores.length > 0) {
            defaultStore = stores[0];
        }

        store.set("stores", stores);
        store.set("storeId", defaultStore?.id);
        store.set("storeId", defaultStore?.id);

        const groups = validatedGroups(store.storeId);
        if (groups.length > 0) {
            store.set("groupId", groups[0].id);
        }
        store.set("isLoading", false);
    }, [store, widget, validatedGroups]);

    const groups = validatedGroups(store.storeId);

    const item = useMemo(() => {
        if (widget.items.length === 0) {
            return null;
        }

        const item = widget.items.find(item => {
            return item.groupId === store.groupId && item.storeId === store.storeId;
        });

        return item || widget.items[0];
    }, [store.storeId, store.groupId, widget]);

    const storeId = store.stores.find(item => item.id === store.storeId) || null;

    if (!item || store.isLoading) {
        return null;
    }

    return (
        <div className={'ppd-widget ppd-widget--base-and-compare'}>
            <div className="ppd-widget__header">
                <div className="ppd-widget__main">
                    <div className="ppd-widget__name">{widget.name}</div>
                    {store.stores.length > 0 && (
                        <div className={classnames("ppd-widget-stores", {
                            'ppd-widget-stores--opened': store.storesIsOpened
                        })}>
                            <div className="ppd-widget-stores__control" onClick={() => store.set("storesIsOpened", !store.storesIsOpened)}>
                                <UiIcon icon={ICONS.LOCATION} size={16} color={store.storeId === null ? '#6f7276' : '#20bf6b'}/>
                                <span>{storeId?.name}</span>
                                <UiIcon icon={ICONS.CHEVRON_DOWN} size={16} color={'#6f7276'}/>
                            </div>
                            <div className="ppd-widget-stores__outer">
                                <div className="ppd-widget-stores__items">
                                    {store.stores.map(item => {
                                        const isMono =
                                            widget.items.filter(widgetItem => widgetItem.storeId === item.id).length === 1
                                            && item.id !== null
                                            && widget.groups.length > 1;

                                        return (
                                            <div
                                                key={item.id}
                                                className={classnames("ppd-widget-stores-item", {
                                                    'ppd-widget-stores-item--active': item.id === store.storeId
                                                })}
                                                onClick={() => {
                                                    store.set("groupId", validatedGroups(item.id)[0]?.id || null)
                                                    store.set("storeId", item.id);
                                                    store.set("storesIsOpened", false);
                                                }}
                                            >
                                                <span>{item.name}</span>
                                                {isMono && (
                                                    <div className={'ppd-widget-stores-item__mono'}>МОНО</div>
                                                )}
                                                <UiIcon icon={ICONS.CHECK_CIRCLE} size={13}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                    <PPDWidgetGroups
                        items={groups}
                        value={store.groupId}
                        name={'groupId'}
                        onChange={store.handleChange}
                    />
                </div>
                <div className="ppd-widget__aside">
                    {widget.typeId === 'base' && (
                        <PPDWidgetItemValueProgress progress={+(item.value || 0)} color={item.color}/>
                    )}
                    {widget.typeId === 'compare' && (
                        <div className={classnames("ppd-widget-compare", {
                            'ppd-widget-compare--positive': item.value >= 0
                        })}>
                            <div className="ppd-widget-compare__header">
                                <div className="ppd-widget-compare__icon">
                                    <img alt={''} src={item.value >= 0 ? compareUp : compareDown}/>
                                </div>
                                <div className="ppd-widget-compare__value">
                                    {item.value > 0 ? '+' : ''}{item.value}%
                                </div>
                            </div>
                            {item.valueCompare?.length > 0 && (
                                <div className="ppd-widget-compare__values">
                                    {item.valueCompare.map((val, index) => (
                                        <div key={index}>{val.join(': ')}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="ppd-widget-properties">
                {item.plan && (
                    <div className="ppd-widget-property">
                        <div className="ppd-widget-property__label">
                            <span>План</span>
                        </div>
                        <div className="ppd-widget-property__value">
                            {currency(number(item.plan), '')}<span> {widget?.units?.long || ''}</span>
                        </div>
                    </div>
                )}
                {item.fact && (
                    <div className="ppd-widget-property">
                        <div className="ppd-widget-property__label">
                            <span>Факт</span>
                            {(!!item.factHint && !item.planIsDone) && (
                                <div className="ppd-widget-property__hint">{item.factHint}</div>
                            )}
                            {!!item.planIsDone && (
                                <UiIcon size={14} icon={ICONS.CHECK} color={'#20bf6b'}/>
                            )}
                        </div>
                        <div className="ppd-widget-property__value">
                            {currency(number(item.fact), '')}<span> {widget?.units?.long || ''}</span>
                        </div>
                    </div>
                )}
            </div>
            <PPDWidgetItemBonus bonus={item.bonus}/>
        </div>
    );
});
