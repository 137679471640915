import { observer } from 'mobx-react';
import moment from "moment/moment";
import React, { useCallback, useEffect } from 'react';
import { COLORS, ICONS } from "shared/constants";
import { CalendarItemEventTypeEnum } from "shared/enums";
import { useHandler, useMedia, useUrlParams } from "shared/hooks";
import { ReservationModel } from "shared/models";

import {
    UiButton,
    UiCheckboxSwitch,
    UiDatePicker,
    UiIcon,
    UiInput,
    UiLoadingSpinner,
    UiModal,
    UiModalTitle,
    UiReservationPlace,
    UiReservations,
    UiSelect,
    UiSliderCasual,
    UiTextarea,
    UiTimePicker,
    UiUsersSearch,
} from "shared/uikit";
import { Service } from "../../Service";

import { Store } from "../../Store";

import './styles.scss';

export const CalendarItemEdit = observer(() => {
    const { calendarItem, reservationPlaces } = Store;

    const urlParams = useUrlParams<{ mode: string, date: string }>({
        mode: 'none',
        date: moment().format()
    });

    useEffect(() => {
        if (urlParams.mode === 'create') {
            Service.showEditModal(urlParams.date)
        }
    }, [urlParams.mode, urlParams])

    useEffect(() => {
        if (!calendarItem) {
            return;
        }
        Service.getReservationPlaces(calendarItem.startMoment.format('Y-M-D'));
        Service.getReservations(calendarItem.startMoment.format('Y-MM-DD'));
    }, [calendarItem, calendarItem?.start])

    const handleClose = useCallback(() => {
        Store.set(Store.modals.edit, {
            isOpened: false,
            newReservation: false
        });
    }, []);

    const handleChange = useHandler(({ name, value }) => {
        if (!calendarItem) {
            return;
        }
        calendarItem[name] = value;
        if (name === 'subject' && calendarItem.reservation) {
            calendarItem.reservation.name = value;
        }
    }, [calendarItem]);

    const handleReservationCreate = useCallback(() => {
        Store.set(Store.modals.edit, 'newReservation', true);
        calendarItem?.update({
            reservation: new ReservationModel({
                name: calendarItem?.name
            })
        });
    }, [calendarItem]);

    const { value: perView, isMobile } = useMedia({
        isMobile: 1,
        isTablet: 2,
        is1024: 1,
        is1280: 2,
        is1440: 3,
    });

    const handleReservationCreationCancel = useCallback(() => {
        Store.set(Store.modals.edit, 'newReservation', false);
        calendarItem?.update({
            reservation: null
        });
    }, [calendarItem]);

    const handleReservationCreationDone = useCallback(() => {
        Store.set(Store.modals.edit, 'newReservation', false);
    }, []);

    if (!calendarItem || Store.modals.edit.isLoading) {
        return (
            <UiModal isAside isOpened={Store.modals.edit.isOpened} onClose={handleClose}>
                <UiLoadingSpinner style={{ paddingTop: isMobile ? 0 : 'calc((100vh - 100px) / 2)' }}/>
            </UiModal>
        );
    }

    if (Store.modals.edit.newReservation && calendarItem.reservation) {
        return (
            <UiModal
                isAside
                onClose={handleClose}
                isOpened={Store.modals.edit.isOpened}
                className="calendar-item-edit"
                title={(
                    <UiModalTitle title='Забронировать переговорную комнату'>
                        <UiButton
                            isOutline
                            isSmall
                            label='Назад'
                            iconBefore={<UiIcon size={12} icon={ICONS.ARROW_LEFT}/>}
                            onClick={handleReservationCreationCancel}
                        />
                        <UiButton
                            isDisabled={!calendarItem.reservation?.startAt || !calendarItem.reservation?.endAt}
                            isSmall
                            label='Подтвердить'
                            onClick={handleReservationCreationDone}
                        />
                    </UiModalTitle>
                )}
            >
                <div className="calendar-item-edit-reservation__date">
                    Бронирование на: <span>{calendarItem.startMoment.format('D MMMM Y')}</span>
                </div>
                {calendarItem.reservation.startAt && (
                    <div className="calendar-item-edit-reservation__date">
                        Время: <span>{calendarItem.reservation.time}</span>
                    </div>
                )}

                <UiSliderCasual
                    perView={perView}
                    items={reservationPlaces}
                    slide={item => (
                        <UiReservationPlace
                            reservationPlace={item}
                            reservation={calendarItem.reservation as ReservationModel}
                            date={calendarItem?.start}
                        />
                    )}
                />
            </UiModal>
        );
    }

    return (
        <UiModal
            isAside
            isOpened={Store.modals.edit.isOpened}
            className="calendar-item-edit"
            onClose={handleClose}
            title={(
                <UiModalTitle title={calendarItem.id ? 'Редактировать событие' : 'Добавить событие'}/>
            )}
        >
            <div className="calendar-item-edit__field">
                <UiInput
                    placeholder={'Введите название'}
                    value={calendarItem.subject}
                    name='subject'
                    onChange={handleChange}
                />
            </div>
            <div className="calendar-item-edit__field">
                <UiTextarea
                    placeholder={'Введите описание'}
                    value={calendarItem.body}
                    name='body'
                    onChange={handleChange}
                />
            </div>
            <div className="calendar-item-edit__field">
                <UiSelect
                    style={{ marginBottom: 0 }}
                    placeholder={'Выберите тип события'}
                    items={CalendarItemEventTypeEnum.items}
                    value={calendarItem.eventTypeId}
                    name='eventTypeId'
                    onChange={handleChange}
                />
            </div>

            <div className="calendar-item-edit-date">
                <div className="calendar-item-edit-date__day">
                    <UiDatePicker
                        valueFormat=''
                        value={calendarItem.start}
                        name='start'
                        onChange={handleChange}
                    />
                    <UiCheckboxSwitch
                        label='Весь день'
                        value={calendarItem.isAllDayEvent}
                        name='isAllDayEvent'
                        onChange={handleChange}
                    />
                </div>
                {
                    !calendarItem.isAllDayEvent && (
                        <div className="calendar-item-edit-date__time">
                            <UiTimePicker
                                value={calendarItem.startTime}
                                name='startTime'
                                onChange={handleChange}
                            />
                            <UiIcon size={12} icon={ICONS.MINUS}/>
                            <UiTimePicker
                                value={calendarItem.endTime}
                                name='endTime'
                                onChange={handleChange}
                            />
                        </div>
                    )
                }
            </div>
            <div className="calendar-item-edit__field">
                <UiSelect
                    value={calendarItem.reminderMinutesBeforeStart}
                    name='reminderMinutesBeforeStart'
                    onChange={handleChange}
                    light={{
                        icon: <UiIcon size={20} icon={ICONS.NOTIFICATIONS}/>,
                        label: 'Напомнить мне:'
                    }}
                    items={[
                        { id: 0, name: 'Никогда' },
                        { id: 1, name: 'За 1 минуту' },
                        { id: 5, name: 'За 5 минут' },
                        { id: 15, name: 'За 15 минут' },
                        { id: 30, name: 'За 30 минут' },
                        { id: 60, name: 'За 1 час' },
                        { id: 120, name: 'За 2 часа' },
                        { id: 180, name: 'За 3 часа' },
                        { id: 60 * 6, name: 'За 6 часов' },
                        { id: 60 * 12, name: 'За 12 часов' },
                        { id: 60 * 24, name: 'За 1 день' },
                        { id: 60 * 24 * 3, name: 'За 3 дня' },
                        { id: 60 * 24 * 7, name: 'За неделю' },
                    ]}
                />
            </div>
            <div className="calendar-item-edit__field">
                <UiUsersSearch
                    isPanel
                    value={calendarItem.attendees}
                    name='attendees'
                    label='Добавить участников'
                    onChange={handleChange}
                    limit={4}
                    canSubmitEmail
                />
            </div>
            <div className="calendar-item-edit__field">
                <UiReservations
                    isPanel
                    items={Store.reservations}
                    value={calendarItem.reservation}
                    name='reservation'
                    onChange={handleChange}
                    actions={(
                        <UiButton
                            isLink
                            isSmall
                            style={{ marginLeft: 'auto' }}
                            label='Добавить новое бронирование'
                            iconAfter={<UiIcon icon={ICONS.PLUS} size={12} color={COLORS.BROWN_1}/>}
                            onClick={handleReservationCreate}
                        />
                    )}
                />
            </div>
            <div className="ui-modal__actions">
                <UiButton isSubmit label='Сохранить' onClick={Service.saveCalendarItem}/>
                <UiButton
                    label='Отмена'
                    onClick={handleClose}
                    isOutline
                />
            </div>
        </UiModal>
    );
});
