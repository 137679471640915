import { observer } from 'mobx-react';
import React from 'react';
import { ICONS, ROUTES } from "shared/constants";
import { useNavigate } from "shared/hooks";
import { NotificationModel } from "shared/models";

import { UiAvatar, UiIcon } from "shared/uikit";

import './index.scss';

type PropsType = {
    notification: NotificationModel,
    onRead?: () => void
}


const notificationTypes: Record<string, {
    icon: string,
    title: string,
    description: string,
}> = {
    TaskCreated: {
        icon: require('./assets/star.svg').default,
        title: 'Добавлена новая задача',
        description: 'Приступите к выполнению.'
    },
    TaskDone: {
        icon: require('./assets/star.svg').default,
        title: 'Задача завершена',
        description: ''
    },
    TaskAlmostExpired: {
        icon: require('./assets/alarm-yellow.svg').default,
        title: 'Задача почти просрочена',
        description: 'Успейте выполнить задачу в срок.'
    },
    TaskExpired: {
        icon: require('./assets/alarm-red.svg').default,
        title: 'Задача просрочена',
        description: 'Договоритесь о переносе сроков'
    },
    CommentCreated: {
        icon: require('./assets/comment.svg').default,
        title: 'Новый комментарий к задаче',
        description: 'Посмотрите комментарии.'
    },
    TaskExpiredAtUpdated: {
        icon: require('./assets/calendar.svg').default,
        title: 'Изменен дедлайн задачи',
        description: 'Ознакомьтесь с новыми сроками'
    },
}

export const Item = observer((
    {
        notification,
        onRead = () => {
        }
    }: PropsType
) => {
    const navigate = useNavigate();
    const item = notificationTypes[notification.typeFormatted] || null;

    const handleOpen = () => {
        navigate(ROUTES.TASK_TRACKER(notification.data.task.spaceId), {
            previewTaskId: notification.data.task.id
        });
    }
    if (!item) {
        return null;
    }
    return (
        <div className="c-tt-notifications-item">
            {notification.data?.task?.ownerUser && (
                <div className="c-tt-notifications-item__avatar">
                    <UiAvatar
                        size={40}
                        onClick={() => {
                            navigate(ROUTES.USER(notification.data.task.ownerUser.id));
                        }}
                        image={notification.data.task.ownerUser.image}
                        label={notification.data.task.ownerUser.previewName}
                    />
                </div>
            )}
            <div className="c-tt-notifications-item__body">
                <div className="c-tt-notifications-item__header">
                    {!!item.icon && (
                        <div
                            className="c-tt-notifications-item__icon"
                            style={{ backgroundImage: `url(${item.icon})` }}
                        />
                    )}
                    <div className="c-tt-notifications-item__title">{item.title}</div>
                    {notification.data?.task?.ownerUser && (
                        <div
                            className="c-tt-notifications-item__user"
                            onClick={() => {
                                navigate(ROUTES.USER(notification.data.task.ownerUser.id));
                            }}
                        >
                            {notification.data.task.ownerUser.previewName}
                        </div>
                    )}
                    <div className="c-tt-notifications-item__date">{notification.createdAtFormatted()}</div>
                    <div className="c-tt-notifications-item__close" onClick={onRead}>
                        <UiIcon size={12} color='#666666' icon={ICONS.CLOSE}/>
                    </div>
                </div>
                <div className="c-tt-notifications-item__content">
                    <div className="c-tt-notifications-item__name" onClick={handleOpen}>
                        «{notification.data.task.name}»
                    </div>
                    {!!item.description && (
                        <div className="c-tt-notifications-item__description">{item.description}</div>
                    )}
                </div>
            </div>
        </div>
    );
});
