import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { CalendarItemModel } from "shared/models";

import './styles.scss';

interface PropsType {
    item: CalendarItemModel;
    toLeft?: boolean,
    style?: React.CSSProperties,
    onClick?: () => void;
    withTime?: boolean;
    withAuthor?: boolean,
}

export const UiCalendarItem = observer((
    {
        item,
        onClick,
        style,
        toLeft = false,
        withAuthor = true,
        withTime = false
    }: PropsType
) => {
    const classNames = classnames('ui-calendar-item', {
        'ui-calendar-item--to-left': toLeft
    });

    return (
        <div className={classNames} onClick={onClick} style={{ ...style, ...item.colors }}>
            <div className="ui-calendar-item__inner">
                <div className="ui-calendar-item__name">
                    {!!(withTime && item.startTimeFormat) && `${item.startTimeFormat} `}
                    {item.name}
                </div>
                {(withAuthor && !!item.organizer?.previewName) && (
                    <div className="ui-calendar-item__description">
                        {item.organizer.previewName}
                    </div>
                )}
                {item.reservation && (
                    <div className="ui-calendar-item__description">
                        {item.reservation.reservationPlace.name}
                    </div>
                )}
            </div>
            <div className="ui-calendar-item-outer">
                <div className="ui-calendar-item-outer__name">{item.name}</div>
                {!!item.organizer?.previewName && (
                    <div className="ui-calendar-item-outer__value">
                        Постановщик: <span>{item.organizer.previewName}</span>
                    </div>
                )}
                {item.reservation && (
                    <div className="ui-calendar-item-outer__value">
                        Бронирование: <span>{item.reservation.reservationPlace.name}</span>
                    </div>
                )}
            </div>
        </div>
    )
});
