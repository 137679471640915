import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { SpaceModel, TaskModel } from "shared/models/TaskTracker";
import {
    UiButton,
    UiDatePicker,
    UiForm,
    UiFormControl,
    UiGrid,
    UiInput,
    UiModal,
    UiModalTitle,
    UiSelect,
    UiTextarea,
    UiTimePicker,
    UiUserSelect
} from "shared/uikit";
import { useMedia, useNavigate, useStore, useUrlParams, useValidation } from "shared/hooks";
import { spacesQuery, tasksGetQuery, tasksSaveQuery } from "shared/queries/TaskTracker";
import { UserModel } from "shared/models";
import { OnChangeHandlerType } from "shared/types";
import { TaskTrackerTaskPriorityEnum } from "shared/enums";
import { ApplicationModule } from "shared/modules";

import { CTaskFiles } from "../CTaskFiles";

import './index.scss';

type PropsType = {
    boardId: number | null,
    id: number | string | null,
    users: UserModel[],
    onSaved: (item: TaskModel) => void
}

export const CTaskSave = observer((
    {
        onSaved,
        id,
        boardId,
    }: PropsType
) => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({});

    const store = useStore(() => ({
        isLoading: true,
        isSubmitted: false,
        isSubmitting: false,
        task: new TaskModel({
            ownerUserId: ApplicationModule.user.id
        }),
        spaces: [] as SpaceModel[],
        newFiles: [] as File[]
    }));

    useEffect(() => {
        (async () => {

            if (!id) {
                return;
            }
            store.set("isLoading", true);

            const spacesResponse = await spacesQuery();
            if (spacesResponse.isSuccess && spacesResponse.data) {
                store.set('spaces', spacesResponse.data.items.map(space => new SpaceModel(space)));
            }

            if (id === 'new') {
                store.set("task", new TaskModel({
                    ownerUserId: ApplicationModule.user.id,
                    boardId: boardId || undefined,
                }))
                store.set("isLoading", false);
                return;
            }

            const { isSuccess, data } = await tasksGetQuery({ id: +id as number });
            if (isSuccess && data) {
                store.set('task', new TaskModel(data.item));
            }
            store.set("isLoading", false);
        })();
    }, [store, id, boardId]);

    const validation = useValidation(store.task, (rules) => ({
        name: rules.required(),
        ownerUserId: rules.required(),
        spaceId: (value: any) => ({
            isValid: (id !== 'new') || (!!boardId) || !!value,
            errorMessage: 'Выберите пространство'
        }),
    }));

    const handleClose = () => {
        if (store.isSubmitting) {
            return;
        }
        navigate(null, {
            ...urlParams,
            saveTaskId: null,
            boardId: null
        }, {}, null);

        store.set('task', new TaskModel({
            ownerUserId: ApplicationModule.user.id
        }));
        store.set('isSubmitted', false);
    }

    const handleSubmit = async () => {
        store.set("isSubmitted", true);
        if (store.isSubmitting || !validation.isValid) {
            return;
        }

        store.set("isSubmitting", true);

        const { isSuccess, data } = await tasksSaveQuery({
            newFiles: store.newFiles,
            ...store.task
        });
        if (isSuccess && data) {
            onSaved(new TaskModel(data.item))
        }
        store.set('newFiles', []);
        store.set("isSubmitting", false);
        store.set("isSubmitted", false)
        navigate(null, {
            ...urlParams,
            saveTaskId: null,
            previewTaskId: data?.item.id,
            boardId: null
        }, {}, null);

    }

    const handleChange: OnChangeHandlerType<any> = (data) => {
        store.task[data.name] = data.value;
    }

    const {value: columns, is320} = useMedia({
        is320: 1,
        is1024: 2
    });

    return (
        <UiModal
            styleBody={{ width: 1000 }}
            isOpened={!!id}
            onClose={handleClose}
            isPortal={false}
            isAside
            className='c-tt-task-save'
            title={id === 'new' ? 'Создание задачи' : 'Изменить задачу'}
        >
            <UiForm onSubmit={handleSubmit}>
                <UiFormControl
                    label={'Название'}
                    isSubmitted={store.isSubmitted}
                    errorMessage={validation.name.errorMessage}
                    isRequired
                >
                    <UiInput
                        placeholder={'Введите название задачи'}
                        name={'name'}
                        value={store.task.name}
                        onChange={handleChange}
                    />
                </UiFormControl>
                {(id === 'new' && !boardId) && (
                    <UiFormControl
                        label={'Пространство'}
                        isSubmitted={store.isSubmitted}
                        errorMessage={validation.spaceId.errorMessage}
                        isRequired
                    >
                        <UiSelect
                            name={'spaceId'}
                            withSearch
                            placeholder={'Выберите пространство'}
                            items={store.spaces}
                            value={store.task.spaceId}
                            onChange={handleChange}
                        />
                    </UiFormControl>
                )}
                <div className="c-tt-task-save__description">
                    <UiTextarea
                        placeholder={'Введите описание задачи'}
                        name={'description'}
                        value={store.task.description}
                        onChange={handleChange}
                    />
                    <CTaskFiles
                        files={store.task.files}
                        newFiles={store.newFiles}
                        onChangeFiles={(files) => store.task.update({ files })}
                        onChangeNewFiles={files => store.set('newFiles', files)}
                    />
                </div>
                <UiGrid columns={columns} gap={16}>
                    <UiFormControl label={'Крайний срок'}>
                        <UiGrid columns={is320 ? 1 : '1fr 110px'} gap={16}>
                            <UiDatePicker
                                valueFormat={''}
                                name={'expiredAt'}
                                value={store.task.expiredAt}
                                onChange={handleChange}
                            />
                            <UiTimePicker
                                value={store.task.expiredAtTime}
                                name={'expiredAtTime'}
                                onChange={handleChange}
                            />
                        </UiGrid>
                    </UiFormControl>
                    <UiFormControl label={'Приоритет'}>
                        <UiSelect
                            items={TaskTrackerTaskPriorityEnum.items}
                            name={'priorityId'}
                            value={store.task.priorityId}
                            onChange={handleChange}
                        />
                    </UiFormControl>
                    <UiFormControl
                        label={'Постановщик'}
                        isSubmitted={store.isSubmitted}
                        errorMessage={validation.ownerUserId.errorMessage}
                        isRequired
                    >
                        <UiUserSelect
                            name={'ownerUserId'}
                            value={store.task.ownerUserId}
                            onChange={handleChange}
                        />
                    </UiFormControl>
                    <UiFormControl label={'Ответственный'}>
                        <UiUserSelect
                            name={'responsibleUserId'}
                            value={store.task.responsibleUserId}
                            onChange={handleChange}
                        />
                    </UiFormControl>
                </UiGrid>
                <UiFormControl label={'Участники'}>
                    <UiUserSelect
                        isMultiple
                        name={'usersIds'}
                        value={store.task.usersIds}
                        onChange={handleChange}
                    />
                </UiFormControl>
                <div className={'ui-modal__actions'}>
                    <UiButton
                        isOutline
                        onClick={handleClose}
                        label={'Отмена'}
                    />
                    <UiButton
                        isLoading={store.isSubmitting}
                        isSubmit
                        label={'Сохранить'}
                    />
                </div>
            </UiForm>
        </UiModal>
    )
});

