import { action, makeAutoObservable, set } from 'mobx';
import moment, { Moment } from "moment";
import lodash from "lodash";

import { CalendarItemModel, ReservationModel, ReservationPlaceModel } from 'shared/models';
import { DATE_FORMAT } from 'shared/constants';
import { CalendarItemTypeEnum } from "shared/enums";

export const Store = new class {
    constructor() {
        makeAutoObservable(this);
    }

    types = [
        { id: "year", name: 'Год' },
        { id: "month", name: 'Месяц' },
        { id: "week", name: 'Неделя' }
    ];
    isLoading = false;
    today = moment();
    date = moment();
    calendarItem: CalendarItemModel | null = null;
    _calendarItems: CalendarItemModel[] = [];
    reservationPlaces: ReservationPlaceModel[] = [];
    reservations: ReservationModel[] = [];
    set = action(set);
    type: "week" | "month" | "year" = "month";
    calendarItemType: string = CalendarItemTypeEnum.Outlook;

    modals = {
        preview: {
            isOpened: false,
        },
        edit: {
            isOpened: false,
            isLoading: false,
            newReservation: false,
        }
    }

    getDays(start: Moment, end: Moment, month: number) {
        let current = start.clone();
        let to = end.clone();

        const days = [];
        while (current.isBefore(to)) {
            const item = current.clone();
            const format = current.format(DATE_FORMAT.DATE);
            days.push({
                id: format,
                moment: item,
                isOuter: current.month() !== month,
                isToday: this.todayFormat === format,
                calendarItems: this.calendarItemsByDate[format] ?? [],
            });
            current = current.add(1, 'days');
        }
        return days;
    }

    get calendarItems() {
        return this._calendarItems.slice().filter(calendarItem => {
            return calendarItem.typeId === this.calendarItemType;
        })
    }

    get calendarItemById() {
        return lodash.keyBy(this.calendarItems, 'id');
    }

    get calendarItemsByDate() {
        return lodash.groupBy<CalendarItemModel>(this.calendarItems, 'startDateFormat');
    }

    get todayFormat() {
        return this.today.format(DATE_FORMAT.DATE);
    }

    get dateFrom() {
        if (this.type === 'year') {
            return this.date.clone().startOf('year');
        }

        if (this.type === 'month') {
            return this.date.clone().startOf('month').startOf('week');
        }

        return this.date.clone().startOf('week');
    }

    get dateTo() {
        if (this.type === 'year') {
            return this.date.clone().endOf('year');
        }

        if (this.type === 'month') {
            return this.date.clone().endOf('month').endOf('week');
        }

        return this.date.clone().add(7, 'days');
    }

    get weekItems() {
        return this.getDays(
            this.date.clone().startOf('week'),
            this.date.clone().endOf('week'),
            this.date.month(),
        );
    }

    get monthItems() {
        return this.getDays(
            this.date.clone().startOf('month').startOf('week'),
            this.date.clone().endOf('month').endOf('week'),
            this.date.month(),
        );
    }

    get yearItems() {
        let date = this.date.clone().startOf('year');

        const days = [];
        for (let i = 0; i < 12; i++) {
            const month = date.clone().set('month', i);
            days.push({
                id: month.format(),
                moment: month,
                days: this.getDays(
                    month.clone().startOf('month').startOf('week'),
                    month.clone().endOf('month').endOf('week'),
                    month.month(),
                )
            });
        }

        return days;
    }
};
