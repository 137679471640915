import { observer } from 'mobx-react';
import { useMedia } from "shared/hooks";

import { UserDashboardWidgetModel } from "shared/models";

import { PPDWidgetBaseAndCompare } from "./components/PPDWidgetBaseAndCompare";
import { PPDWidgetBonuses } from "./components/PPDWidgetBonuses";
import { PPDWidgetGrid } from "./components/PPDWidgetGrid";
import { PPDWidgetTable } from "./components/PPDWidgetTable";
import { PPDWidgetBaseAndCompareMobile } from "./components/PPDWidgetBaseAndCompareMobile";

import './styles.scss';

type PropsType = {
    widget: UserDashboardWidgetModel,
    view: string
}

export const PPDWidget = observer(({ widget, view }: PropsType) => {
    const { is320, is768 } = useMedia();

    if (widget.typeId === 'base' || widget.typeId === 'compare') {
        if ((is320 || is768) && widget.stores.length > 0) {
            return <PPDWidgetBaseAndCompareMobile widget={widget}/>
        }

        if (view === 'table' && widget.stores.length > 0 && widget.items.filter(item => item.storeId !== null).length > 0) {
            return <PPDWidgetTable widget={widget}/>
        }

        return <PPDWidgetBaseAndCompare widget={widget}/>;
    }

    if (widget.typeId === 'bonuses') {
        return <PPDWidgetBonuses widget={widget}/>
    }

    if (widget.typeId === 'grid') {
        return <PPDWidgetGrid widget={widget}/>
    }

    return null;
});
