import React from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import { Swiper as SwiperClass } from 'swiper';

import { useStore } from "shared/hooks";
import { COLORS, ICONS } from "shared/constants";

import { UiIcon } from '../UiIcon';

import './styles.scss';

type PropsType = {
    items: any[],
    slideClassName?: string,
    withDots?: boolean,
    perPage?: number | 'auto',
    loop?: boolean,
    gap?: number,
    slide?: (item: any) => React.ReactNode | JSX.Element
}

export const UiSlider = observer((
    {
        withDots,
        slideClassName,
        items,
        loop = false,
        slide = () => null,
        gap = 0,
        perPage = 1
    }: PropsType
) => {
    const store = useStore(() => ({
        slideIndex: 0,
        swiper: null as null | SwiperClass
    }));

    if (items.length === 0) {
        return null;
    }

    return (
        <div className="ui-slider">
            <div className="ui-slider__inner">
                <Swiper
                    loop={loop}
                    slidesPerView={perPage}
                    spaceBetween={gap}
                    onSlideChange={(swiper: SwiperClass) => {
                        store.set("slideIndex", swiper.realIndex)
                    }}
                    onSwiper={(swiper: SwiperClass) => {
                        store.set("swiper", swiper)
                    }}
                >
                    {items.map((item, index) => {
                        return (
                            <SwiperSlide key={index} className={classnames('ui-slider-slide', slideClassName)}>
                                {slide(item)}
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            {withDots && (
                <div className="ui-slider-dots">
                    {items.map((_, index) => {
                        const className = classnames('ui-slider-dots__item', {
                            'ui-slider-dots__item--active': index === store.slideIndex
                        });
                        return <div key={index} className={className} onClick={() => {
                            store.swiper?.slideTo(index);
                        }}/>;
                    })}
                </div>
            )}
            {(loop || items.length > +perPage) && (
                <>
                    <div
                        className="ui-slider__arrow ui-slider__arrow--left"
                        onClick={() => {
                            store.swiper?.slidePrev();
                        }}
                    >
                        <UiIcon icon={ICONS.CHEVRON_LEFT} size={22} color={COLORS.BLACK}/>
                    </div>
                    <div
                        className="ui-slider__arrow ui-slider__arrow--right"
                        onClick={() => {
                            store.swiper?.slideNext();
                        }}
                    >
                        <UiIcon icon={ICONS.CHEVRON_RIGHT} size={22} color={COLORS.BLACK}/>
                    </div>
                </>
            )}
        </div>
    )
});
